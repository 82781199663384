@use "breakpoints";
@use "fonts";

.main {
  min-height: 100vh;
  background: var(--bg-color);

  @include breakpoints.for-tablet {
    min-height: var(--app-height);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 839px;
  height: 508px;
  position: relative;

  @include breakpoints.for-tablet {
    width: 100%;
    padding: 0 16px;
  }

  .text {
    position: absolute;
    font-family: fonts.$inter;
    font-weight: 700;
    font-size: 420px;
    line-height: 508px;
    color: rgba(7, 57, 55, 0.1);
    z-index: 0;
    letter-spacing: -10px;

    @include breakpoints.for-tablet {
      font-size: 211px;
      line-height: 255px;
    }
  }

  .itsEmptyHere {
    font-family: fonts.$inter;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: var(--green);
    z-index: 1;

    @include breakpoints.for-tablet {
      font-size: 26px;
      line-height: 31px;
    }
  }

  .message {
    font-size: 20px;
    line-height: 28px;
    color: var(--green);
    text-align: center;
    z-index: 1;
    margin: 40px 0;

    @include breakpoints.for-tablet {
      font-size: 18px;
      line-height: 25px;
      margin: 24px 0;
    }
  }

  .button {
    z-index: 1;
  }
}
